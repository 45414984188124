import React, {FunctionComponent} from 'react';
import './Popup.scss';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {closeModal} from '../../store/reducers/modalSlice';
import {Modal as RModal} from 'react-responsive-modal';

interface PopupProps {
  onClose?: () => void;
  children: React.ReactNode;
  modalID: string;
}

const Popup: FunctionComponent<PopupProps> = ({onClose, children, modalID}) => {
  const dispatch = useAppDispatch();
  const openedModalID = useAppSelector(
    (state) => state.modalSlice.currentOpenModal,
  );

  const handlerOnClose = () => {
    removeActiveClassFromButtons();
    onClose && onClose();
    document.body.style.marginRight = '0px';
    dispatch(closeModal());
  };

  const removeActiveClassFromButtons = () => {
    const activeButtons = document.querySelectorAll('.active');
    activeButtons.forEach((button) => button.classList.remove('active'));
  };

  if (openedModalID !== modalID) {
    return null;
  }
  return (
    <RModal
      open={openedModalID === modalID}
      onClose={handlerOnClose}
      center
      showCloseIcon={false}
      blockScroll={true}
      classNames={{
        modal: 'modal',
        overlay: 'overlay',
        root: 'root',
      }}>
      <div className="container">
        <div className="cross" onClick={handlerOnClose}>
          <svg
            width="26"
            height="25"
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1 0.5L25 24.5M25 0.5L1 24.5" stroke="#333333" />
          </svg>
        </div>
        <div className="content">{children}</div>
      </div>
    </RModal>
  );
};

export default Popup;
