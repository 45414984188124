import {combineReducers, configureStore} from '@reduxjs/toolkit';
import modalSlice from './reducers/modalSlice';
import userStateReducer from './reducers/userStateSlice';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userStateReducer'],
};

const rootReducer = combineReducers({
  modalSlice,
  userStateReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
