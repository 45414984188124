import Popup from '../../Popup/Popup.tsx';
import React, {useState} from 'react';
import {Button} from 'antd';
import {useTonConnectUI} from '@tonconnect/ui-react';
import './BuyAttemptsPopup.scss';

const BuyAttemptsPopup = () => {
  const [tonConnectUI] = useTonConnectUI();
  const [amount, setAmount] = useState(0);
  const [isButtonActive, setIsButtonActive] = useState(false); // Состояние для активации кнопки "Пополнить"
  const [selectedAmount, setSelectedAmount] = useState(''); // Состояние для хранения выбранной суммы
  const [isSubmitted, setIsSubmitted] = useState(false); // Состояние для отображения сообщения об успешной отправке транзакции

  // Функция обработки выбора суммы
  const handleSelectAmount = (selectedAmount) => {
    setSelectedAmount(selectedAmount); // Обновляем выбранную сумму
    setAmount(selectedAmount); // Устанавливаем выбранную сумму в состояние
    setIsButtonActive(true); // Активируем кнопку "Пополнить"
  };
  const handleSendTransaction = () => {
    if (amount < 0) {
      alert('Сумма транзакции не может быть отрицательной');
      return;
    }

    const amountToSend = amount * 1000000000;

    const token = localStorage.getItem('token');

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const transaction = {
      validUntil: Math.floor(Date.now() / 1000) + 360,
      messages: [
        {
          address:
            '0:1a432b57e81bf29ef6acf77843ec465e951843e58eb3473eac986bc55959cfec',
          amount: amountToSend.toString(),
        },
      ],
    };

    tonConnectUI
      .sendTransaction(transaction)
      .then((data) => {
        if (data) {
          // Send a request to the backend if data is received
          fetch(`${process.env.REACT_APP_API_URL}/api/slave/users/payment`, {
            method: 'POST',
            headers: {
              ...headers, // Передача токена в заголовках запроса к бэкенду
            },
            body: JSON.stringify({
              ton: amountToSend,
            }),
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              console.log('Success');
              setIsSubmitted(true);
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        }
      })
      .catch((e) => console.log('e', e));
  };
  return (
    <Popup modalID="buyAttempts">
      <div className="transaction-form-container">
        {isSubmitted ? (
          <p>Спасибо! Ваши попытки уже начислены</p>
        ) : (
          <>
            <div className="amount-buttons">
              <Button
                className={selectedAmount === '0.25' ? 'select' : ''}
                onClick={() => handleSelectAmount('0.25')}>
                1.5k попыток = 0.25 💎
              </Button>
              <Button
                className={selectedAmount === '2.5' ? 'select' : ''}
                onClick={() => handleSelectAmount('2.5')}>
                20k попыток = 2.5 💎
              </Button>
              <Button
                className={selectedAmount === '22' ? 'select' : ''}
                onClick={() => handleSelectAmount('22')}>
                200k попыток = 22 💎
              </Button>
              <Button
                className={selectedAmount === '200' ? 'select' : ''}
                onClick={() => handleSelectAmount('200')}>
                2M попыток = 200 💎
              </Button>
            </div>

            <Button
              type="primary"
              onClick={handleSendTransaction}
              disabled={!isButtonActive}
              className="send-button">
              оплатить 💎
            </Button>
          </>
        )}
      </div>
    </Popup>
  );
};

export default BuyAttemptsPopup;
