import React, { useRef, useState } from "react";
import { Input, message, Button } from "antd";

import "./PasswordForm.scss";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { openModal } from "../../store/reducers/modalSlice";
import clickSound from "../../assets/sounds/mario.wav";
import coin from "../../assets/gifs/coin.gif";

message.config({
  maxCount: 2,
});

const PasswordForm = ({ onSendPassword }) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(
    (state) => state.userStateReducer.currentBTCwallet
  );

  const [animate, setAnimate] = useState(false);
  const [password, setPassword] = useState("");

  const audio = new Audio(clickSound);

  let predel = Number(localStorage.getItem("predel")) || 0;

  const handleAnimation = () => {
    console.log(1);
    setAnimate(true);
    setTimeout(() => setAnimate(false), 700);
  };

  const handleSendPassword = () => {
    const token = localStorage.getItem("token");
    const isMuted = localStorage.getItem("isMuted");

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    if (!password) {
      message.error("Поле пароля не может быть пустым");
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/api/slave/pass/password`, {
      method: "POST",
      headers: {
        ...headers,
      },
      body: JSON.stringify({ password }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(data.message || "Ошибка сервера");
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.password) {
          handleAnimation();
          isMuted !== "true" && audio.play();
          message.success("Пароль отправлен");
          setPassword("");
          predel = predel - 1;
          localStorage.setItem("predel", JSON.stringify(predel));
          onSendPassword();
        } else {
          throw new Error(data.message || "Ошибка при отправке пароля");
        }
      })
      .catch((error) => {
        message.error(error.message || "Что-то пошло не так");
      });
  };

  const handleSendPasswordRandom = (amount: number) => {
    const token = localStorage.getItem("token");
    const isMuted = localStorage.getItem("isMuted");

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    fetch(`${process.env.REACT_APP_API_URL}/api/slave/pass/password/random`, {
      method: "POST",
      headers: {
        ...headers,
      },
      body: amount ? JSON.stringify({ multiply: amount }) : "",
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(data.message || "Ошибка сервера");
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.password) {
          if (amount === 1) {
            handleAnimation();
          }
          handleAnimation();
          isMuted !== "true" && audio.play();
          message.success("Пароль отправлен");
          setPassword("");
          const updatePredel = predel - amount;

          localStorage.setItem(
            "predel",
            updatePredel === 0 ? "0" : JSON.stringify(updatePredel)
          );
          onSendPassword();
        } else {
          throw new Error(data.message || "Ошибка при отправке пароля");
        }
      })
      .catch((error) => {
        message.error(error.message || "Что-то пошло не так");
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendPassword();
    }
  };

  const handleCharKeyPress = (e) => {
    const isCyrillic = /[а-яА-ЯёЁ]/.test(e.currentTarget.value);
    console.log(e.currentTarget.value);
    if (isCyrillic) {
      message.error("Можно вводить только латинские буквы, символы и цифры");

      e.preventDefault();
    } else setPassword(e.target.value);
  };

  // useEffect(() => {
  //   const isMuted = localStorage.getItem('isMuted');
  //   setIsMuted(isMuted === 'true' ? true : false);
  // },[]);

  return (
    <div className="PasswordForm_root">
      <div className="input_frame">
        <div className="address">
          <div className="input-container">
            {animate && <img src={coin} alt="coin" className="coin-gif" />}

            <Input
              className="password-input"
              placeholder="введите пароль"
              value={password}
              onChange={(e) => handleCharKeyPress(e)}
              onKeyPress={handleKeyPress}
            />
            <button className="try-pass" onClick={handleSendPassword}></button>
          </div>
          BTC address: {state}
        </div>
      </div>

      <div className="form-button">
        <div className="try-count">
          Cгенерировать пароль
          <Button
            className="try-rand"
            onClick={() => handleSendPasswordRandom(1)}
          >
            МНЕ ПОВЕЗЕТ
          </Button>
          <div className="multiplier_buttons">
            <Button
              className="multiplier_button"
              onClick={() => handleSendPasswordRandom(100)}
            >
              x100
            </Button>
            <Button
              className="multiplier_button"
              onClick={() => handleSendPasswordRandom(1000)}
            >
              x1000
            </Button>
            <Button
              className="multiplier_button"
              onClick={() => handleSendPasswordRandom(10000)}
            >
              x10K
            </Button>
          </div>
          {/* <img src="" alt="money" className="video" /> */}
        </div>
        <div className="try-count">
          Осталось попыток: {predel || 0}
          <Button
            className="try-add"
            onClick={() => dispatch(openModal("buyAttempts"))}
          >
            ЕЩЕ ПОПЫТОК
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PasswordForm;
