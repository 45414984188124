import Popup from '../../Popup/Popup.tsx';
import React from 'react';
import './weWin.scss';

const WeWinPopup = () => {
  return (
    <Popup modalID="weWinPopup">
      <div className="WeWin">
        <h2>Ура! Мы победили!</h2>
        <p>
          Пароль к BTC address: <span>18jANvQ6AuVGJnea4EhmXiAf6bHR5qKjPB</span>{' '}
          подобран!
        </p>
        <button>Ваш выигрыш 100 BTS</button>
      </div>
    </Popup>
  );
};

export default WeWinPopup;
