import Popup from '../../Popup/Popup.tsx';
import {Input} from 'antd';
import './UnlockingApply.scss';
import React, {useState} from 'react';

const UnlockingApplyPopup = () => {
  const [tg, setTg] = useState('');
  const [wallet, setWallet] = useState('');
  const [error, setError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async () => {
    if (!tg || !wallet) {
      setError('Все поля должны быть заполнены');
      return;
    }

    const token = localStorage.getItem('token');

    const body = {tg_nickname: tg, wallet};

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/slave/wallet`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        },
      );

      if (response.ok) {
        // Handle successful response
        console.log('Request successful');
        setIsSubmitted(true);
      } else {
        // Handle errors
        console.error('Request failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Popup modalID="unlockingApply">
      <div className="UnlockingForm">
        <h2>заявка на восстановление пароля кошелька</h2>
        {isSubmitted ? (
          <p>Спасибо за заявку! Мы свяжемся с вами в ближайшее время</p>
        ) : (
          <>
            <p>
              После заявки, мы отправим анкету, оценим вероятность
              восстановления и после этого заключим договор на разблокировку с
              юридическими обязательствами и гарантиями
            </p>
            <form>
              <div className="input">
                <label htmlFor="tg">Telegram</label>
                <Input
                  type="text"
                  id="tg"
                  placeholder="@findpass"
                  value={tg}
                  onChange={(e) => setTg(e.target.value)}
                />
              </div>
              <div className="input">
                <label htmlFor="wallet">Wallet address</label>
                <Input
                  type="text"
                  id="wallet"
                  placeholder="18jANvQ6AuVGJnea4EhmXiAf6bHR5qKjPB"
                  value={wallet}
                  onChange={(e) => setWallet(e.target.value)}
                />
              </div>
            </form>
            {error && <p className="error">{error}</p>}

            <button onClick={handleSubmit}>отправить</button>
          </>
        )}
      </div>
    </Popup>
  );
};

export default UnlockingApplyPopup;
