import React from 'react';

const TwitchPlayer = () => {
  return (
    <>
      <iframe
        src="https://vk.com/video_ext.php?oid=-227624578&id=456239018&hd=1&autoplay=1"
        width="100%"
        height="100%"
        allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
        title="vk"></iframe>
      {/* <TwitchEmbedVideo
        theme="dark"
        allowfullscreen="false"
        layout="video"
        channel="find_pass"
        width="100%"
        height="100%"
      /> */}
    </>
  );
};

export default TwitchPlayer;
