import Popup from '../../Popup/Popup.tsx';
import React from 'react';
import './AboutProject.scss';

const AboutProjectNoAuthPopup = () => {
  return (
    <Popup modalID="AboutProjectNoAuth">
      <div className="AboutProject">
        <h2>О проекте</h2>
        <p>
          Мы помогаем вспомнить пароль и заработать:
          <br />
          <br /> 1. Привяжи свой Telegram-кошелек для получения вознаграждений.
          <br /> 2. Возвращайся на сайт.
          <br /> 3. Вводи свои варианты паролей или используй наш генератор
          попыток.
          <br /> 4. Получай токен{' '}
          <b>
            FNP <span className="coin" />
          </b>{' '}
          за каждую попытку.
          <br /> 5. Получи большое вознаграждение за успешный подбор пароля.
          <br /> 6. Читай подробности о кошельке.
        </p>
      </div>
    </Popup>
  );
};
export default AboutProjectNoAuthPopup;
