import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import TwitchPlayer from "../../components/twitch-video.tsx";
import { TonConnectButton, useTonWallet } from "@tonconnect/ui-react";
import authStore from "../../store/authStore.js";
import PasswordTableFrame from "../../components/PasswordTableFrame/PasswordTableFrame.tsx";
import PasswordForm from "../../components/PasswordForm/PasswordForm.tsx";
import "./Attempt.scss";
import React from "react";
import fetchPaginationPassword from "../../utils/fetchPaginationPassword.ts";
import { useAppDispatch, useAppSelector } from "../../store/hooks.ts";
import { openModal } from "../../store/reducers/modalSlice.ts";
import AboutProjectNoAuth from "../../components/popups/AboutProjectNoAuth/AboutProject.tsx";

interface IProps {
  passwords: any;
  setPasswords: React.Dispatch<React.SetStateAction<any>>;
}

export const Attempt = observer(({ passwords, setPasswords }: IProps) => {
  const dispatch = useAppDispatch();
  const wallet = useTonWallet();

  const currentWallet = useAppSelector(
    (state) => state.userStateReducer.currentBTCwallet
  );

  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    if (wallet) {
      authStore.setWallet(wallet);
      authStore.tonLogin().then(() => {
        setToken(localStorage.getItem("token"));
      });
      onSendPassword();
    }
  }, [wallet]);

  const onSendPassword = () => {
    if (!token) return;
    fetchPaginationPassword(token, 1, currentWallet).then((data) => {
      setPasswords(data);
    });
  };

  //ГРЕШШНОСТЬ
  useEffect(() => {
    if (!token || !wallet) return;

    const intervalId = setInterval(() => {
      onSendPassword();
      console.log("Запрос на обновление паролей");
    }, 5000);

    // Очищаем интервал при размонтировании компонента
    return () => clearInterval(intervalId);
  }, [wallet, currentWallet]);

  return (
    <>
      {wallet?.account && token ? (
        <div className="Attempt_root">
          <PasswordForm onSendPassword={onSendPassword} />

          <div className="bottom-side">
            <PasswordTableFrame
              token={token}
              passwords={passwords}
              setPasswords={setPasswords}
            />

            <div className="TwitchPlayer">
              <div className="header">
                <span className="icon"></span>
                <h3>Трансляция подбора онлайн 24/7</h3>
              </div>
              <TwitchPlayer />
            </div>
          </div>
        </div>
      ) : (
        <div className="Attempt_root noAuth">
          <div className="ton-buttons">
            <div className="ton-button">
              <button onClick={() => dispatch(openModal("AboutProjectNoAuth"))}>
                Описание
              </button>
            </div>

            <TonConnectButton className="ton-button" />

            <div className="buttonUnlockingApply">
              <button onClick={() => dispatch(openModal("unlockingApply"))}>
                Разблокируйте мой кошелек / заявка
              </button>
            </div>
          </div>
        </div>
      )}
      <AboutProjectNoAuth />
    </>
  );
});
